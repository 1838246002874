import React from 'react';
import {Provider} from 'react-redux';
import {store} from './store/store';

import {  ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import {Rours, Router} from './Routes';
import { Loading,Alert,Notify, } from './view/components';
import './global.css';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: "#F12C4C",
    },
    secondary: {
      main: "#FC4A1A",
    },
    tertiary: {
      main: "#F7B733",
    },
    buttoncolor: {
      main: "#F12C4C",
    },
  },

  props: {
    // Nome do componente ⚛️
    MuiTextField: {
      // As propriedades padrão para mudar
      variant: "outlined",
      fullWidth:true
    },
    MuiSelect:{
      variant: "outlined",
      fullWidth:true
    },
    MuiInput:{
      variant: "outlined",
      fullWidth:true
    },
  },
});


const App =() =>{
  return(
    <Provider store={store}>   
    <ThemeProvider theme={theme}>
   
      <Loading/>
      <Alert/>
      <Notify/>


      <Rours />
   
    </ThemeProvider>
    </Provider>

   
  )
}

export default App;