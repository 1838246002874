import React from 'react';
import { Outlet } from 'react-router-dom'
import HeaderAdmin from '../../components/headeradmin'


import { LayoutContainer } from './styles'

export function AdminLayout() {
  React.useEffect(()=>{
    const t= localStorage.getItem('blazeadmin_token');
    if(!t){
      window.location.replace('/');

    }

  
   },[])
  return (
    <div>
      <HeaderAdmin  />
      <Outlet />
    </div>
  )
}
