import { Http, HttpAuth } from '../../config/Http'
import { changeLoading } from './loading.action'
import { changeNotify } from './notify.action'

export const actionTypes = {
    CHANGE: 'USUARIO_CHANGE',
    ERROR: 'USUARIO_ERROR',
    SUCCESS: 'USUARIO_SUCCESS',
    SHOW:'USUARIO_SHOW',
    INDEX:'USUARIO_INDEX',
    INDEXDOUBLES:'INDEXDOUBLES_INDEX',
}

export const change = (payload) => ({
    type: actionTypes.CHANGE,
    payload
})

export const error = (payload) => ({
    type: actionTypes.ERROR,
    payload
})

export const success = (payload) => ({
    type: actionTypes.SUCCESS,
    payload
})


export const indexResponse = (payload) => ({
    type: actionTypes.INDEX,
    payload
})

export const showResponse =(payload) => ({
    type: actionTypes.SHOW,
    payload,
})

export const index = () => dispatch => {
    return HttpAuth.get('/usuario')
    .then(res => typeof res !== 'undefined' && dispatch(indexResponse(res.data)))
}

export const setUserToken = token => dispatch => {
    localStorage.setItem('blazeadmin_token', token);
    dispatch( change({
        nome: '',
        email: '',
        senha: '',
        telefone:'',
    }) )

    dispatch(success(true))
}


export const lerexcel = (data,size) => dispatch => {
    console.log(data);
    dispatch(changeLoading({
        open: true,
        msg: 'Cadastrando usuários...'
    }));
    if(size > (8 * 1024 *1024)){
        dispatch(changeLoading({open:false}) );
         dispatch(changeNotify({open:true,msg:'Arquivo muito grande maximo 8 mb'}));
        
     }
     const config = {     
        headers: { 'content-type': 'multipart/form-data' }
    }
    return HttpAuth.post('/usuario/lerexcel', data, {
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));
       
         
          if(progress <= 100){
            
            dispatch(changeLoading({open:true}) );
           
          }

          return progress;
          
        }
      },config)
    .then(res =>{
        
        dispatch(changeLoading({open:false}) );
        if(typeof res !== 'undefined'){
       
             if(res.data.error){
              
                 dispatch(success(false));
                 dispatch(error(res.data.error));
             }

         if(res.status === 200){
            dispatch(changeNotify({open:true,msg:'Cadastrado com sucesso'}));
            dispatch(changeLoading({open:false}) );
            dispatch(success(true));
            return res.data;
             

            
         }
        }
     })
        .catch(error => {
        
            dispatch( changeLoading({open: false}) )

            if(error.response) {
                dispatch(error(error.response.data.error))
            }
        })
}

export const store = data => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Cadastrando usuário...'
    }));
  
    return HttpAuth.post('/usuario', data)
        .then(res => {
            
            dispatch( changeLoading({open: false}) )
            if(typeof res !== 'undefined'){
                if(res.data.error){
                    dispatch(success(false));
                    dispatch(error(res.data.error));
                }
   
                if(res.status === 201){
                    dispatch(success(true));
                    dispatch( changeNotify({
                        open: true,
                        class: 'success',
                        msg: res.data.msg
                    }) )
                   
                }
            }
        })
        .catch(error => {
        
            dispatch( changeLoading({open: false}) )

            if(error.response) {
                dispatch(error(error.response.data.error))
            }
        })
}

export const update = (data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Usuario'
    }))

    return HttpAuth.put('/usuario/update/'+data.id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}


export const show = (id) => dispatch => {
    return HttpAuth.get('/usuario/show/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const getUserToken = () => dispatch => {
    return HttpAuth.get('/usuario/decoude/')
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}


export const mudastatus = (id) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Status'
    }))
 
    return HttpAuth.put('usuario/mudastatus/'+id)
           .then(res =>{
              
                 
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                      if(res.data.error){
                          dispatch(success(false));
                          dispatch(error(res.data.error));
                      }

                      if(res.status === 201){
                        dispatch(success(false));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                          dispatch(index());
                         
                      }
                 }
           })
}

export const zeraweb = (id) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Zerando  acesso web'
    }))
 
    return HttpAuth.put('usuario/zerarweb/'+id)
           .then(res =>{
              
                 
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                      if(res.data.error){
                          dispatch(success(false));
                          dispatch(error(res.data.error));
                      }

                      if(res.status === 201){
                        dispatch(success(false));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                          dispatch(index());
                         
                      }
                 }
           })
}


export const zeraapp = (id) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Zerando  acesso do App'
    }))
 
    return HttpAuth.put('usuario/zerarapp/'+id)
           .then(res =>{
              
                 
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                      if(res.data.error){
                          dispatch(success(false));
                          dispatch(error(res.data.error));
                      }

                      if(res.status === 201){
                        dispatch(success(false));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                          dispatch(index());
                         
                      }
                 }
           })
}

export const doubles = () => dispatch => {
    return HttpAuth.get('/usuario/doubles')
    .then(res => typeof res !== 'undefined' && dispatch(indexResponse(res.data)))
}

export const listcrash = () => dispatch => {
    return HttpAuth.get('/usuario/crashs')
    .then(res => typeof res !== 'undefined' && dispatch(indexResponse(res.data)))
}


export const listdoublehora = (data) => dispatch => {
    if(typeof data != "undefined"){
    dispatch(changeLoading({
        open:true,
        msg:'Buscando Aguarde'
    }))
}
    return HttpAuth.post('/usuario/doubledata',data)
    .then(res => {
        dispatch( changeLoading({open: false}) )
        return(
            typeof res !== 'undefined' && dispatch(indexResponse(res.data))
        )
    })
    .catch(error => {
        
        dispatch( changeLoading({open: false}) )

        if(error.response) {
            dispatch(error(error.response.data.error))
        }
    })
}
export const listcrashhora = (data) => dispatch => {
    if(typeof data != "undefined"){
    dispatch(changeLoading({
        open:true,
        msg:'Buscando Aguarde'
    }))
}
    return HttpAuth.post('/usuario/crashdata',data)
    .then(res => {
        dispatch( changeLoading({open: false}) )
        return(
            typeof res !== 'undefined' && dispatch(indexResponse(res.data))
        )
    })
    .catch(error => {
        
        dispatch( changeLoading({open: false}) )

        if(error.response) {
            dispatch(error(error.response.data.error))
        }
    })
}


export const usuariocompermissao = (id) => dispatch => {
    return HttpAuth.get('/usuario/usuariocompermissao/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}






