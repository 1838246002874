

import React,{Suspense,lazy} from 'react';
import { BrowserRouter,Routes, Route } from 'react-router-dom'
import {CircularProgress} from '@mui/material';
import { DefaultLayout } from './view/layout/DefaultLayout';
import { AdminLayout } from './view/layout/AdminLayout';

const Login =  lazy (() => import ('./view/pages/login'));
const Inicio =  lazy (() => import ('./view/pages/Home'));

const SemAtendimento =  lazy (() => import ('./view/pages/consultas/SemAtendimento'));
const EmAtendimento =  lazy (() => import ('./view/pages/consultas/EmAtendimento'));
const Finalizados =  lazy (() => import ('./view/pages/consultas/Finalizados'));
const Detalhes =  lazy (() => import ('./view/pages/consultas/Detalhes'));


const Usuario =  lazy (() => import ('./view/pages/usuario/edit'));
const Usuarios =  lazy (() => import ('./view/pages/usuario/index'));


const Arquivo =  lazy (() => import ('./view/pages/Arquivo/index'));
const Permissoes =  lazy (() => import ('./view/pages/usuario/permissoes'));

const SemAtendimentoAdm = lazy (() => import ('./view/pages/consutasadmin/SemAtendimentos'));
const EmAtendimentoAdm = lazy (() => import ('./view/pages/consutasadmin/EmAtendimentos'));

const Emissao = lazy (() => import ('./view/pages/consutasadmin/Emissao'));

export function Rours() {
  return (
    <Suspense fallback={<div className="d-flex justify-content-center mt-5 pt-5"><CircularProgress /></div>}>
      <BrowserRouter>
      <Routes>
       
        <Route path="/" element={<DefaultLayout />}>
      
                
                  <Route path="/login"    element={<Login />} />
                  <Route path="/semocorrencia"    element={<SemAtendimento />} />
                  <Route path="/emocorrencia"    element={<EmAtendimento />} />
                  <Route path="/ocorrenciaFinalizado"    element={<Finalizados />} />
                  <Route path="/Detalhes"    element={<Detalhes />} />

                  <Route path="/"   element={<Inicio />} />
             
         </Route>

         <Route path="/admin" element={<AdminLayout />}>
            <Route path=""  element={<SemAtendimentoAdm />} />
            <Route path="finalizar"  element={<EmAtendimentoAdm />} />

            <Route path="usuario"  element={<Usuario />} />
            <Route path="usuario/:id"  element={<Usuario />} />
            <Route path="usuarios"  element={<Usuarios />} />
            <Route path="emissao/:id"  component={Emissao} />

            {/* <Route path="/arquivo"  element={<Arquivo />} />               */}
            {/*Permissao*/}
            <Route path="permissoes/:id"  element={<Permissoes />} />     
         </Route>

    </Routes>
    </BrowserRouter>

    </Suspense>
  )
}
