import {combineReducers} from 'redux';
import  loadinReducers from './loading.reducer';
import  notifyReducers from './notify.reducer';
import  alertReducers from './alert.reducer';

import usuarioReducers from './usuario.reducer';
import permissaoReducers from './permissao.reducer'
import locaisReducers from './locais.reducer'
import ocorrenciasReducers from './ocorrencia.reducer'
import problemasReducers from './problemas.reducer'
import authReducers from './auth.reducer';




const rootReducer = combineReducers({
    loadinReducers,
    notifyReducers,
    alertReducers,
    usuarioReducers,
    permissaoReducers,
    authReducers,
    locaisReducers,
    ocorrenciasReducers,
    problemasReducers,



})

export default rootReducer;