import { Http, HttpAuth } from '../../config/Http'
import { changeLoading } from './loading.action'
import { changeNotify } from './notify.action'
import Axios from 'axios';
export const actionTypes = {
    CHANGE: 'OCORRENCIA_CHANGE',
    ERROR: 'OCORRENCIA_ERROR',
    SUCCESS: 'OCORRENCIA_SUCCESS',
    SHOW:'OCORRENCIA_SHOW',
    INDEX:'OCORRENCIA_INDEX',
}

export const change = (payload) => ({
    type: actionTypes.CHANGE,
    payload
})

export const error = (payload) => ({
    type: actionTypes.ERROR,
    payload
})

export const success = (payload) => ({
    type: actionTypes.SUCCESS,
    payload
})


export const indexResponse = (payload) => ({
    type: actionTypes.INDEX,
    payload
})

export const showResponse =(payload) => ({
    type: actionTypes.SHOW,
    payload,
})

export const index = () => dispatch => {
    return HttpAuth.get('/ocorrencia/listCadastro')
    .then(res => typeof res !== 'undefined' && dispatch(indexResponse(res.data)))
}


export const store =  data => async dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Cadastrando usuário...'
    }));
    
    const dataip = await Axios.get('https://api.ipify.org?format=json');
       console.log('var',dataip.data.ip)
       data.ip = dataip.data.ip;
       console.log('data',data)
    return HttpAuth.post('/ocorrencia/store', data)
        .then(res => {
            
            dispatch( changeLoading({open: false}) )
            if(typeof res !== 'undefined'){
                if(res.data.error){
                    dispatch(success(false));
                    dispatch(error(res.data.error));
                }
   
                if(res.status === 201){
                    dispatch(success(true));
                    dispatch(changeNotify({
                        open: true,
                        class: 'success',
                        msg: res.data.msg
                    }) )
                   
                }
            }
        })
        .catch(error => {
        
            dispatch( changeLoading({open: false}) )

            if(error.response) {
                dispatch(error(error.response.data.error))
            }
        })
}

export const atender =  data => async dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Cadastrando usuário...'
    }));

    return HttpAuth.post('/ocorrencia/atenderocorrencia', data)
        .then(res => {
            
            dispatch( changeLoading({open: false}) )
            if(typeof res !== 'undefined'){
                if(res.data.error){
                    dispatch(success(false));
                    dispatch(error(res.data.error));
                }
   
                if(res.status === 201){
                    dispatch(success(true));
                    dispatch(changeNotify({
                        open: true,
                        class: 'success',
                        msg: res.data.msg
                    }) )
                   
                }
            }
        })
        .catch(error => {
        
            dispatch( changeLoading({open: false}) )

            if(error.response) {
                dispatch(error(error.response.data.error))
            }
        })
}

export const finalizar =  data => async dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Cadastrando usuário...'
    }));
    console.log(data)

    return HttpAuth.post('/ocorrencia/finalizarocorrencia', data)
        .then(res => {
            
            dispatch( changeLoading({open: false}) )
            if(typeof res !== 'undefined'){
                if(res.data.error){
                    dispatch(success(false));
                    dispatch(error(res.data.error));
                }
   
                if(res.status === 201){
                    dispatch(success(true));
                    dispatch(changeNotify({
                        open: true,
                        class: 'success',
                        msg: res.data.msg
                    }) )
                   
                }
            }
        })
        .catch(error => {
        
            dispatch( changeLoading({open: false}) )

            if(error.response) {
                dispatch(error(error.response.data.error))
            }
        })
}


export const semAtendimento = (offset,filtros) => dispatch => {
    console.log('offset',offset)
    console.log('filtros',filtros)
    return HttpAuth.get(`/ocorrencia/semocorrencia/${offset}?datainicio=${filtros?.datainicio}&datafim=${filtros?.datafim}&codigo=${filtros?.codigo}`)
    .then(res => typeof res !== 'undefined' && dispatch(indexResponse(res.data)))
}

export const emAtendimento = (offset,filtros) => dispatch => {
    console.log('offset',offset)
    console.log('filtros',filtros)
    return HttpAuth.get(`/ocorrencia/emocorrencia/${offset}?datainicio=${filtros?.datainicio}&datafim=${filtros?.datafim}&codigo=${filtros?.codigo}`)
    .then(res => typeof res !== 'undefined' && dispatch(indexResponse(res.data)))
}

export const finalizados = (offset,filtros) => dispatch => {
    return HttpAuth.get(`/ocorrencia/finalizados/${offset}?datainicio=${filtros?.datainicio}&datafim=${filtros?.datafim}&codigo=${filtros?.codigo}`)
    .then(res => typeof res !== 'undefined' && dispatch(indexResponse(res.data)))
}

export const show = (id) => dispatch => {
    return HttpAuth.get('/ocorrencia/show/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}









