import { Http, HttpAuth } from '../../config/Http'
import { changeLoading } from './loading.action'
import { changeNotify } from './notify.action'
import {usuariocompermissao} from './usuario.action'
export const actionTypes = {
    CHANGE: 'LOCAL_CHANGE',
    ERROR: 'LOCAL_ERROR',
    SUCCESS: 'LOCAL_SUCCESS',
    SHOW:'LOCAL_SHOW',
    INDEX:'LOCAL_INDEX',
}

export const change = (payload) => ({
    type: actionTypes.CHANGE,
    payload
})

export const error = (payload) => ({
    type: actionTypes.ERROR,
    payload
})

export const success = (payload) => ({
    type: actionTypes.SUCCESS,
    payload
})


export const indexResponse = (payload) => ({
    type: actionTypes.INDEX,
    payload
})

export const showResponse =(payload) => ({
    type: actionTypes.SHOW,
    payload,
})

export const index = () => dispatch => {
    return HttpAuth.get('/locais')
    .then(res => typeof res !== 'undefined' && dispatch(indexResponse(res.data)))
}











