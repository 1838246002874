import React from 'react';
import {Link, Redirect } from 'react-router-dom';
import {FaUsers,FaPaste,FaLaptop,FaWhatsapp,FaSignOutAlt,FaAngleUp,FaAngleDown, FaLink} from 'react-icons/fa';
import { MenuItem ,MenuList,AppBar,Toolbar,IconButton,Typography,Drawer,
         Divider,List,ListItem,ListItemText,ListItemIcon,Collapse } from '@mui/material';
import { MdMenu }from 'react-icons/md';
import { GiEntryDoor }from 'react-icons/gi';

import {deslogar,usuariologado} from '../../../store/actions/auth.action';

import {useSelector,useDispatch} from 'react-redux'
export default function Header(props) {
  const dispatch = useDispatch();

  const [state,setSatate] = React.useState({
    open:false
  });

  const [collapse,setCollapse] = React.useState({
    double:false,
    crash:false,
    historico:false
});

  const [permisao,setPermisao] = React.useState(0)
  const [isPermissao,setIsPermissao] = React.useState(0)
  
  React.useEffect(()=>{
    dispatch(usuariologado()).then(res =>{
       const adminpermission = res.payload.usuario.permissoes.find(res=>res.nome == "ADM")
       setIsPermissao(adminpermission);
      setPermisao(res.payload.usuario.permissoes.length);
      
    })
  },[])

    return (
        <>
         {(window.innerWidth < 577) ? 
            <AppBar position="fixed">
            <Toolbar>
              <IconButton edge="start"  color="inherit" aria-label="menu" onClick={() => setSatate({open:true})}>
                <MdMenu />
              </IconButton>
              <Typography variant="h6" >
                {props.title}
              </Typography>
              {props.button}
            </Toolbar>
          </AppBar>
           :
           <nav className="header navbar navbar-expand-lg navbar-light bg-light p-0">
              <div className="container">
                <Link  className="navbar-brand" to="/">
                   <img src="/logo.png" alt="LOGO" height="50" />
                   
                </Link>
                

                <ul className="navbar-nav">
               

                 <li className="nav-item dropdown">
                     <Link className="nav-link dropdown-toggle" to="#"   data-bs-toggle="dropdown">
                       <FaPaste className="icon-lg mr-2"/> Ocorrencia
                     </Link>
                     <MenuList className="dropdown-menu">
                        <MenuItem className="dropdown-item"> 
                        <Link className="" to="/">Cadastro</Link>
                        </MenuItem>
                      
                        
                     </MenuList>
                     
                  </li>

                  <li className="nav-item dropdown">
                     <Link className="nav-link dropdown-toggle" to="#"   data-bs-toggle="dropdown">
                       <FaPaste className="icon-lg mr-2"/> Consultas
                     </Link>
                     <MenuList className="dropdown-menu">
                        <MenuItem className="dropdown-item"> 
                        <Link className="" to="/semocorrencia">Sem Atendimento</Link>
                        </MenuItem>
                     
                        <MenuItem className="dropdown-item">
                          <Link className="" to="/emocorrencia">Em Atendimento</Link>
                        </MenuItem>

                        <MenuItem className="dropdown-item">
                          <Link className="" to="/ocorrenciaFinalizado">Finalizados</Link>
                        </MenuItem>
                        
                        
                     </MenuList>
                  </li>

                  

                
                 

                  <li className="nav-item">
                     <a className="nav-link"  href={`http://api.whatsapp.com/send?1=pt_BR&phone=5535984313982`} target="_blank" rel="noreferrer">
                       <FaWhatsapp className="icon-lg mr-2"/> Ajuda
                     </a>
                  </li>
                   {permisao > 0 &&
                     <li className="nav-item">
                      <Link className="nav-link" to="/admin">
                        <FaLink className="icon-lg mr-2"/> Admin
                      </Link>
                    </li>
                 
                  }

              {permisao > 0 ? 
                     <li className="nav-item" onClick={() => dispatch(deslogar())}>
                     <Link className="nav-link" to="#">
                       <GiEntryDoor className="icon-lg mr-2"/> Sair
                     </Link>
                  </li>
                  :
                  <li className="nav-item">
                  <Link className="nav-link" to="/login">
                    <FaSignOutAlt className="icon-lg mr-2"/> Logar
                  </Link>
                  </li>
                  }
                

                </ul>
              </div>
           </nav>
         }

         <Drawer anchor="left" open={state.open} onClose={() => setSatate({open:false})}>
             <div style={{width:320, maxWidth:window.innerWidth - 70}}>
               <List component="nav" className="menu-mobile">
                     <ListItem>
                     <img className="img-fluid logo-mobile" src="/logo.png" alt="LOGO" />
                     </ListItem>
                   

                     <Divider className="mt-2 mb-3" />
                     
                     <ListItem button onClick={() => setCollapse({double: (collapse.double) ? false : true})}>
                        <ListItemIcon>
                        <FaPaste /> 
                        </ListItemIcon>
                        <ListItemText primary="Ocorrencia"/>
                        {(collapse.double) ? <FaAngleUp /> : <FaAngleDown />}
                     </ListItem>

                     <Collapse in={collapse.double} timeout="auto" unmountOnExit>
                       <List component="div" disablePadding>
                           <ListItem>
                           <Link to="/" style={{display:'flex'}}>
                             <ListItemText className="pl-5" primary="Cadastro" />
                             </Link>
                           </ListItem>
                        
                       </List>
                     </Collapse>


                        
                     <ListItem button onClick={() => setCollapse({crash: (collapse.crash) ? false : true})}>
                        <ListItemIcon>
                        <FaPaste /> 
                        </ListItemIcon>
                        <ListItemText primary="Consultas"/>
                        {(collapse.crash) ? <FaAngleUp /> : <FaAngleDown />}
                     </ListItem>

                     <Collapse in={collapse.crash} timeout="auto" unmountOnExit>
                       <List component="div" disablePadding>
                           <ListItem>
                           <Link to="/semocorrencia" style={{display:'flex'}}>
                             <ListItemText className="pl-5" primary="Sem Atendimento" />
                             </Link>
                           </ListItem>
                        
                           <ListItem>
                           <Link to="/emocorrencia" style={{display:'flex'}}>
                             <ListItemText className="pl-5" primary="Em Atendimento" />
                             </Link>
                           </ListItem>
                           <ListItem>
                           <Link to="/ocorrenciaFinalizado" style={{display:'flex'}}>
                             <ListItemText className="pl-5" primary="Finalizados" />
                             </Link>
                           </ListItem>
                          
                       </List>
                     </Collapse>


                     <a  href={`http://api.whatsapp.com/send?1=pt_BR&phone=5535984313982`} target="_blank" rel="noreferrer">
                     <ListItem>
                        <ListItemIcon>
                        <FaWhatsapp /> 
                        </ListItemIcon>
                        <ListItemText primary="Ajuda"/>
                     </ListItem>
                     </a>
                    
                     <Divider className="mt-2 mb-2" />
                     {permisao > 0 &&
                       <ListItem >
                       <Link to="/admin">
                       <ListItemIcon>
                       <FaLink />
                       <ListItemText primary="Admin"/>
                       </ListItemIcon>
                    
                       </Link>
                    </ListItem>
                    }
                     {permisao > 0 ?
                       <ListItem onClick={() => dispatch(deslogar())}>
                       <Link to="/home">
                       <ListItemIcon>
                       <GiEntryDoor /> 
                       <ListItemText primary="Sair"/>
                       </ListItemIcon>
                    
                       </Link>
                    </ListItem>
                    :

                    <ListItem>
                    <Link to="/login">
                    <ListItemIcon>
                    <FaSignOutAlt  /> 
                    <ListItemText primary="Entrar"/>
                    </ListItemIcon>
           
                    </Link>
                 </ListItem>
                      }
                   
                     
               </List>
             </div>
         </Drawer>
        </>
    )
}
